.card-container {
    width: calc(75svw);
}
  
.card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* .recptbanner {
    font-size: ;
} */

.recptimg {
    width: calc(33.33vh/1.1);
}

.sloder {
    width: 2000px;
}

.instrgif {
    bottom: calc(5.0svh);
}

.scroll-container {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
}


.mainblock {
    left: calc(50.00vw - 150px);
}

.startbut {
    bottom: 12.00svh;
}